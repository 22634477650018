








import { defineComponent } from '@vue/composition-api'

import { CustomLink } from '../../../atoms/Link'

import { breadcrumbItemProps } from '../Breadcrumbs.hooks'

/**
 * Component capable to render `BreadcrumbItem` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const BreadcrumbItem = defineComponent({
  name: 'BreadcrumbItem',
  components: { CustomLink },
  props: breadcrumbItemProps
})
export default BreadcrumbItem
