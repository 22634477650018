















import { defineComponent } from '@vue/composition-api'

import { breadcrumbsProps } from './Breadcrumbs.hooks'

import { CustomBreadcrumbItem } from './partials'

/**
 * Component capable to render `Breadcrumbs` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Breadcrumbs = defineComponent({
  name: 'Breadcrumbs',
  components: { CustomBreadcrumbItem },
  props: breadcrumbsProps
})
export default Breadcrumbs
